/* import React, { useState } from "react";
import logo from "./logo.svg"; */
import "./App.css";
import Container from "./Container";
/* import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { sortAndDeduplicateDiagnostics } from "typescript"; */
/* 
const msg: string = "hi there";

type Coord = {
  x: number;
};

function RetirementGraph({ data }: { data: Coord[] }) {
  return (
    <LineChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
      <Line type="monotone" dataKey="x" stroke="#8884d8" />
    </LineChart>
  );
}
 */
function App() {
  /*   const [data, updateData] = useState<Coord[]>([]);

  const addData = (val: string) => {
    console.log("adding", val);
    const num: number = Number.parseInt(val);
    const c: Coord = { x: num };

    updateData([...data, c]);
  };
 */
  return <Container />;
}

export default App;
