import { Fragment, useState, useReducer, useEffect } from "react";
import { UIField } from "./UserInput";
import * as t from "./Types";

import { round, projectedData } from "./projections";
import { search } from "./Suggestions";

const formatter = Intl.NumberFormat("en-US");

export const flexOptions = {
  inflationRate: "Inflation Rate",
  interestRate: "Interest Rate",
  currentSavings: "Current Savings",
  yearlyAddition: "Yearly Addition",
  retirementAge: "Retirement Age",
  liveTillAge: "Live Till Age",
  incomeDuringRetirement: "Income During Retirement",
  incomeDuringRetirementLastsTillAge: "Income During Retirement Lasts Till Age",
  interestDuringIncomeDuringRetirement:
    "Interest During Income During Retirement",
  ratio401k: "401k Withdrawal Ratio",
  pension: "Pension",
  socialSecurity: "Social Security",
  realReturnsStartingFromYear: "Real returns starting from year",
};

export const integerFields = new Set([
  "retirementAge",
  "liveTillAge",
  "incomeDuringRetirementLastsTillAge",
  "realReturnsStartingFromYear",
]);

export const calculateFlexTable = (state: t.State) => {
  if (state.flexMax <= state.flexMin) return null;
  try {
    const stepSize = (state.flexMax - state.flexMin) / 10;

    let val = state.flexMin;
    let table = [];
    let i = 0;
    while (i < 11) {
      val = Number(val.toPrecision(5));
      if (integerFields.has(state.flexAttr)) {
        val = Math.round(val);
      }
      const newState = { ...state, [state.flexAttr]: val };
      const validWithdrawal = search(
        0,
        100000,
        newState,
        "monthlyWithdrawal",
        true
      );
      table.push({
        key: val,
        data: [val, "$" + formatter.format(validWithdrawal)],
      });
      val += stepSize;
      i += 1;
    }
    return table;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export function Flex({
  flexAttr,
  flexMin,
  flexMax,
  onChange,
  className,
}: {
  flexAttr: keyof t.State;
  flexMin: number;
  flexMax: number;
  onChange: any;
  className: string;
}) {
  return (
    <div className={"container mx-auto py-8 " + className}>
      <h2 className="text-2xl md:text-3xl font-georgia mb-2 text-darkest">
        Flexible calculator
      </h2>
      <p className="font-georgia text-xl text-dark mb-2">
        A retirement calculator's output is not a sure thing, partly because the
        inputs can change. So sometimes it's nice to give a range for a field,
        instead of one value. That's what you can do here.
        <br />
        For example, inflation can be variable, so depending on what the
        inflation is, here's what your monthly withdrawal would need to be
        under, to make your retirement income last.
      </p>
      <div className="grid grid-cols-2 gap-4">
        <div className="mt-2">
          <label className=" inline-block text-sm font-medium text-darkest all-small-caps">
            Field
          </label>

          <select
            className="
      w-full
      mt-1
      px-3
      py-1.5
      text-base
      font-normal
      rounded-md border border-lightest shadow-sm text-darkest      rounded
      "
            value={flexAttr}
            onChange={(e) => {
              onChange("flexAttr", e.target.value);
            }}
          >
            {Object.keys(flexOptions).map((key: string) => {
              return (
                <option value={key} key={key}>
                  {flexOptions[key as keyof typeof flexOptions]}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-1 grid grid-cols-2 gap-2">
          <UIField
            name="Min"
            tip=""
            val={flexMin}
            onChange={(val) => onChange("flexMin", val)}
          />
          <UIField
            name="Max"
            tip=""
            val={flexMax}
            onChange={(val) => onChange("flexMax", val)}
          />
        </div>
      </div>
    </div>
  );
}
