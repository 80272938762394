import { useState } from "react";
import * as t from "./Types";

type DataRow = {
  key: number | string;
  data: (number | string)[];
};

export default function SimpleTable({
  headers,
  data,
  className,
}: {
  headers: string[];
  data: DataRow[];
  className?: string;
}) {
  const formatter = Intl.NumberFormat("en-US");

  className = className || "";
  return (
    <div className={"border-b border-gloss " + className}>
      <div className="mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {headers.map((header) => {
                return (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    key={header}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data.map((row, i) => {
              return (
                <tr
                  key={row.key}
                  className={i % 2 === 0 ? "bg-white" : "bg-ultralight"}
                >
                  {row.data.map((cell: string | number) => {
                    return (
                      <td
                        key={cell}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                      >
                        {cell}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
