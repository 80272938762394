import { Fragment, useState, useReducer } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import * as t from "./Types";

type Field = {
  name: string;
  stateKey: string;
  tip?: string;
};

type Section = {
  name: string;
  desc: string;
  fields: Field[];
};

const basicSection: Section = {
  name: "1. How much will you start with?",
  desc: "First, let's calculate how much you will have saved when you start your retirement.",
  fields: [
    {
      name: "Current Savings",
      stateKey: "currentSavings",
      tip: "Don't include your 401(k) savings here. During retirement, the only tax you will pay on this is 15% capital gains tax.",
    },
    {
      name: "Current Savings 401k",
      stateKey: "currentSavings401k",
      tip: "You will need to pay income tax on this.",
    },

    {
      name: "Yearly Addition",
      stateKey: "yearlyAddition",
      tip: "Post-tax savings.",
    },
    {
      name: "Yearly Addition 401k",
      stateKey: "yearlyAddition401k",
      tip: "Pre-tax savings.",
    },
    { name: "Current Age", stateKey: "currentAge" },
    { name: "Retirement Age", stateKey: "retirementAge" },
    { name: "Interest Rate", stateKey: "interestRate" },
  ],
};

const inRetirementSection: Section = {
  name: "2. During retirement",
  desc: "How much do you expect to spend during retirement?",
  fields: [
    { name: "Live Till Age", stateKey: "liveTillAge" },
    {
      name: "Monthly Withdrawal",
      stateKey: "monthlyWithdrawal",
      tip: "Net, today's dollars. We will add the taxes for you, and adjust for inflation.",
    },
    {
      name: "Interest During Retirement",
      tip: "4% is a standard choice.",
      stateKey: "interestDuringRetirement",
    },
  ],
};

const additionalIncomeSection: Section = {
  name: "3. Additional income during retirement",
  desc: "Will you get money from other sources besides your savings?",
  fields: [
    {
      name: "Pension",
      stateKey: "pension",
      tip: "Monthly amount, gross, today's dollars.",
    }, //
    { name: "Pension Start Age", stateKey: "pensionStartAge" }, //
    {
      name: "Social Security",
      stateKey: "socialSecurity",
      tip: "Monthly amount, gross, today's dollars. We will assume you start withdrawing at age 70, and that the checks you receive are adjusted for inflation yearly.",
    }, //
    {
      name: "Income During Retirement",
      stateKey: "incomeDuringRetirement",
      tip: "Monthly amount, gross, today's dollars.",
    }, //
    {
      name: "Income Lasts Till Age",
      stateKey: "incomeDuringRetirementLastsTillAge",
      tip: "This is specifically for the income you have during retirement, not your full-time job. If you are retired but your partner is working, you can put their income here.",
    }, //
    {
      name: "Interest Rate During Income During Retirement",
      stateKey: "interestDuringIncomeDuringRetirement",
      tip: "This is the rate of return on investments after you retire, but while you are still getting income during retirement. This field is here so you can be more aggressive while you're still getting income, if you want.",
    }, //
  ],
};

const otherSection: Section = {
  name: "4. Other",
  desc: "We assume a 3% rate of inflation. If you want, you can change that here.",
  fields: [
    { name: "Inflation Rate", stateKey: "inflationRate" },
    {
      name: "401k Withdrawal ratio",
      stateKey: "ratio401k",
      tip: "What percent of your withdrawal comes from your 401k? Note: Starting at age 70, you will be required to at least take the minimum distribution.",
    },
    {
      name: "Real returns starting from year",
      stateKey: "realReturnsStartingFromYear",
      tip: "If you specify a year, this will use the S&P 500 returns starting from that year, instead of the interest rate you put in. This is a good way to test your scenario against the ups and downs of the market, instead of consistent returns. We have data from 1960 on. When data runs out, we'll fall back to the interest rate you specified. This does not replace the interest rate during retirement that you put in, because presumably you want to be more conservative at that point.",
    },
  ],
};

const sections: Section[] = [
  basicSection,
  inRetirementSection,
  additionalIncomeSection,
  otherSection,
];

type StateValue = { val: number };

function Section({
  state,
  section,
  onChange,
}: {
  state: t.State;
  section: Section;
  onChange: (key: keyof t.State, val: any) => any;
}) {
  return (
    <div className="container mx-auto py-8 border-b border-b-gloss">
      <h2 className="text-2xl md:text-3xl font-georgia mb-2 text-darkest">
        {section.name}
      </h2>
      <p className="font-georgia text-xl text-dark mb-2">{section.desc}</p>
      <div className="grid grid-cols-2 gap-4">
        {section.fields.map((field) => (
          <UIField
            {...field}
            val={state[field.stateKey as keyof t.State] as number}
            onChange={(val) => onChange(field.stateKey as keyof t.State, val)}
            key={field.stateKey}
          />
        ))}
      </div>
    </div>
  );
}

export function UIField({
  name,
  tip,
  val,
  onChange,
}: {
  name: string;
  tip?: string;
  val: number;
  onChange: (val: any) => any;
}) {
  return (
    <div className="mt-1">
      <label className=" inline-block lg:text-sm text-sm md:text-lg font-medium text-darkest all-small-caps">
        {name}
      </label>

      <div className="mt-1">
        <input
          type="number"
          inputMode="decimal"
          pattern="[0-9.]*"
          className=" w-full rounded-md outline-dark shadow-sm text-darkest border-none text-base"
          value={val.toString()}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        {/*        <input
          type="range"
          min={0}
          max={10000000}
          value={val}
          onChange={(e) => {
            console.log("slider", e.target.value);

            onChange(e.target.value);
          }}
        />*/}
      </div>
      {tip && (
        <p className="text-sm md:text-lg lg:text-sm text-dark pt-2">{tip}</p>
      )}
    </div>
  );
}

export default function UserInput({
  state,
  onChange,
}: {
  state: t.State;
  onChange: (key: keyof t.State, val: any) => any;
}) {
  return (
    <div>
      {sections.map((section) => (
        <Section
          state={state}
          section={section}
          onChange={onChange}
          key={section.name}
        />
      ))}
    </div>
  );
}
