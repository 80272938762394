import { useState, useEffect } from "react";
import * as t from "./Types";
import { TrashIcon } from "@heroicons/react/24/outline";

type SavedItem = {
  id: number;
  state: t.State;
  name: string;
  created_at: number;
  savingsStartsAt: number;
  savingsEndsAt: number;
};

export default function Saved({
  state,
  onClick,
  savingsStartsAt,
  savingsEndsAt,
}: {
  state: t.State;
  onClick: (state: t.State) => any;
  savingsStartsAt: number;
  savingsEndsAt: number;
}) {
  const [name, setName] = useState("");
  let defaultItem: SavedItem[] = [];
  const [current, setCurrent] = useState(defaultItem);

  useEffect(() => {
    let json = localStorage.getItem("saved");
    setCurrent(json ? JSON.parse(json) : []);
  }, []);

  const save = () => {
    let toSave = {
      id: current.length,
      state,
      name,
      savingsStartsAt,
      savingsEndsAt,
      created_at: Date.now(),
    };
    let cur = [...current];
    cur.push(toSave);
    setCurrent(cur);
    setName("");
    localStorage.setItem("saved", JSON.stringify(cur));
  };

  const deleteSaved = (id: number) => {
    let newCurrent: SavedItem[] = [];
    current.forEach((saved) => {
      if (saved.id !== id) newCurrent.push(saved);
    });
    setCurrent(newCurrent);
    localStorage.setItem("saved", JSON.stringify(newCurrent));
  };
  return (
    <>
      <h2 className="text-2xl md:text-3xl font-georgia mb-2 text-darkest mt-10">
        Save
      </h2>
      <p className="font-georgia text-xl text-dark mb-2">
        If you like, you can save your current inputs, to be able to go back to
        them later quickly. Data is only saved in your browser in local storage,
        so if you switch to another browser, this data won't be available there.
        If you clear cookies or local storage, this data will be gone.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 pb-4 gap-4">
        <div>
          <label className=" inline-block font-medium lg:text-sm text-sm md:text-lg text-darkest all-small-caps">
            Name
          </label>
          <input
            type="text"
            className={
              "w-full rounded-md outline-dark shadow-sm text-darkest border-none text-base mt-2"
            }
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <p
            className="px-3 py-2 mt-9 inline-block w-full h-min cursor-pointer rounded-md bg-light text-black all-small-caps text-center"
            onClick={() => {
              save();
            }}
          >
            Save {name}
          </p>
        </div>
      </div>
      <label className=" inline-block font-medium lg:text-sm text-sm md:text-lg text-darkest all-small-caps mt-4">
        Previously saved
      </label>

      {current.length === 0 && (
        <p className="font-georgia text-xl text-dark mb-2">
          You haven't saved anything yet.
        </p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 pb-12 gap-4">
        {current.map((saved: SavedItem) => {
          let classes =
            "px-3 py-2 mt-2 inline-block w-full h-min cursor-pointer rounded-md text-black all-small-caps col-span-8 text-center active:bg-dark ";
          classes += saved.savingsEndsAt > 0 ? "bg-glossalt" : "bg-gloss";
          return (
            <div key={saved.id} className="grid grid-cols-10 gap-2">
              <p
                className={classes}
                onClick={() => {
                  onClick(saved.state);
                }}
              >
                {saved.name}
              </p>
              <p
                className="px-3 py-2 mt-2 inline-block w-full h-min cursor-pointer rounded-md bg-light text-black all-small-caps col-span-2"
                onClick={() => {
                  deleteSaved(saved.id);
                }}
              >
                <TrashIcon className="h-6 mx-auto" />
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
}
