import * as t from "./Types";

const b = (taxRate: t.Percent, bottom: number, top: number): t.TaxBracket => {
  return {
    taxRate,
    bottom,
    top,
  };
};

export const dataYear = "2023";

export const singleBrackets = [
  b(0.1, 0, 10275),
  b(0.12, 10276, 41775),
  b(0.22, 41776, 89075),
  b(0.24, 89076, 170050),
  b(0.32, 170051, 215950),
  b(0.35, 215951, 539900),
  b(0.37, 539901, Infinity),
];

export const jointBrackets = [
  b(0.1, 0, 20550),
  b(0.12, 20551, 83550),
  b(0.22, 83551, 178150),
  b(0.24, 178151, 340100),
  b(0.32, 340101, 431900),
  b(0.35, 431901, 647850),
  b(0.37, 647851, Infinity),
];

export const singleBracketsMN = [
  b(0.0535, 0, 28080),
  b(0.068, 28081, 92230),
  b(0.0785, 92231, 171220),
  b(0.0985, 171221, Infinity),
];

export const jointBracketsMN = [
  b(0.0535, 0, 41050),
  b(0.068, 41051, 163060),
  b(0.0785, 163061, 284810),
  b(0.0985, 284811, Infinity),
];

export const jointStandardDeduction = 25900;
export const standardDeduction = 12950;

export const jointStandardDeductionMN = 25800;
export const standardDeductionMN = 12900;

const applyBrackets = (income: number, brackets: t.TaxBracket[]): number => {
  let taxPaid = 0;
  brackets.some((bracket: t.TaxBracket) => {
    let toSubtract = bracket.top - bracket.bottom;
    let remaining = income - toSubtract;
    if (remaining < 0) {
      // this is the last tax bracket
      // all remaining money will get taxed at this rate
      taxPaid += income * bracket.taxRate;
      return true;
    }
    taxPaid += toSubtract * bracket.taxRate;
    income -= toSubtract;
  });
  return taxPaid;
};

// effective
export const estimatedFedTax = (
  income: number,
  filingJointly: boolean,
  useStandardDeduction: boolean = false
): [number, t.Percent] => {
  if (useStandardDeduction) {
    if (filingJointly) {
      income -= jointStandardDeduction;
    } else {
      income -= standardDeduction;
    }
    if (income < 0) return [0, 0];
  }

  let brackets = filingJointly ? jointBrackets : singleBrackets;
  const taxPaid = applyBrackets(income, brackets);
  let effectiveRate = taxPaid / income;
  if (Number.isNaN(effectiveRate)) effectiveRate = 0;
  return [taxPaid, effectiveRate];
};

export const estimatedStateTax = (
  income: number,
  filingJointly: boolean,
  useStandardDeduction: boolean = false
): [number, t.Percent] => {
  if (useStandardDeduction) {
    if (filingJointly) {
      income -= jointStandardDeductionMN;
    } else {
      income -= standardDeductionMN;
    }
    if (income < 0) return [0, 0];
  }

  let brackets = filingJointly ? jointBracketsMN : singleBracketsMN;
  const taxPaid = applyBrackets(income, brackets);
  let effectiveRate = taxPaid / income;
  if (Number.isNaN(effectiveRate)) effectiveRate = 0;
  return [taxPaid, effectiveRate];
};

export const estimatedFicaTax = (income: number): [number, t.Percent] => {
  if (income === 0) return [0, 0];
  return [income * 0.0765, 0.0765];
};

const et = (taxPaid: number, effectiveRate: t.Percent): t.EffectiveTax => {
  return { taxPaid, effectiveRate };
};

export const estimatedTax = (
  income: number,
  filingJointly: boolean,
  useStandardDeduction: boolean = false
): { fed: t.EffectiveTax; state: t.EffectiveTax; fica: t.EffectiveTax } => {
  return {
    fed: et(...estimatedFedTax(income, filingJointly, useStandardDeduction)),
    state: et(
      ...estimatedStateTax(income, filingJointly, useStandardDeduction)
    ),
    fica: et(...estimatedFicaTax(income)),
  };
};
