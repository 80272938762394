import * as t from "./Types";
import { projectedData } from "./projections";
import { integerFields } from "./Flex";
const searchEscape = (
  min: number,
  max: number,
  unsuccessfulData: t.State,
  key: keyof t.State,
  flip: boolean
): number => {
  let result = 10000;
  let mid = 0;
  let count = 0;
  if (key === "flexAttr") return 0;
  while (count < 100 && (result < -1000 || result > 1000)) {
    mid = (min + max) / 2.0;

    unsuccessfulData[key] = mid;
    result =
      projectedData(unsuccessfulData).savingsEndsAt -
      projectedData(unsuccessfulData).savingsStartsAt;
    if (flip) {
      if (result < -1000) {
        max = mid;
      } else if (result > 1000) {
        min = mid;
      }
    } else {
      if (result < -1000) {
        min = mid;
      } else if (result > 1000) {
        max = mid;
      }
    }
    count += 1;
  }
  return Number(mid.toFixed(1));
};

export const search = (
  min: number,
  max: number,
  _unsuccessfulData: t.State,
  key: keyof t.State,
  flip: boolean // true means if retirement wont work, decrease value (eg w withdrawal). false means increase (eg w retirement age)
): number => {
  let result = 10000;
  let mid = 0;
  let count = 0;
  if (key === "flexAttr") return 0;

  let unsuccessfulData = { ..._unsuccessfulData };
  while (count < 20 && (result < -1000 || result > 1000)) {
    mid = (min + max) / 2.0;
    if (integerFields.has(key)) {
      mid = Math.round(mid);
    }

    unsuccessfulData[key] = mid;
    result = projectedData(unsuccessfulData, true).savingsEndsAt;

    if (flip) {
      if (result < -1000) {
        max = mid;
      } else if (result > 1000) {
        min = mid;
      }
    } else {
      if (result < -1000) {
        min = mid;
      } else if (result > 1000) {
        max = mid;
      }
    }
    count += 1;
  }
  return Number(mid.toFixed(1));
};

const suggest = (
  type: keyof t.State,
  currentVal: number,
  unsuccessfulData: t.State,
  min: number,
  max: number,
  flip: boolean
) => {
  const suggestedValue = search(min, max, unsuccessfulData, type, flip);
  return { type, suggestedValue };
};

const suggestEscape = (
  type: keyof t.State,
  currentVal: number,
  unsuccessfulData: t.State,
  min: number,
  max: number,
  flip: boolean
) => {
  const suggestedValue = searchEscape(min, max, unsuccessfulData, type, flip);
  return { type, suggestedValue };
};

export const getSuggestions = (
  data: t.ProjectedData,
  state: t.State
): t.Suggestion[] => {
  let suggestions: t.Suggestion[] = [];
  if (data.savingsEndsAt < 0) {
    suggestions.push(
      suggest(
        "yearlyAddition",
        state.yearlyAddition,
        { ...state },
        0,
        1000000000,
        false
      )
    );
    suggestions.push(
      suggest("retirementAge", state.retirementAge, { ...state }, 0, 100, false)
    );

    suggestions.push(
      suggest(
        "monthlyWithdrawal",
        state.monthlyWithdrawal,
        { ...state },
        0,
        100000,
        true
      )
    );
  }
  return suggestions;
};

export const getSuggestionsEscape = (
  data: t.ProjectedData,
  state: t.State
): t.Suggestion[] => {
  let escapeSuggestions: t.Suggestion[] = [];
  if (data.savingsEndsAt < data.savingsStartsAt) {
    escapeSuggestions.push(
      suggestEscape(
        "yearlyAddition",
        state.yearlyAddition,
        { ...state },
        0,
        1000000000,
        false
      )
    );
    escapeSuggestions.push(
      suggestEscape(
        "retirementAge",
        state.retirementAge,
        { ...state },
        0,
        100,
        false
      )
    );

    escapeSuggestions.push(
      suggestEscape(
        "monthlyWithdrawal",
        state.monthlyWithdrawal,
        { ...state },
        0,
        100000,
        true
      )
    );
  }
  return escapeSuggestions;
};

export const Suggestions = ({
  suggestions,
  escapeSuggestions,
}: {
  suggestions: t.Suggestion[];
  escapeSuggestions: t.Suggestion[];
}) => {
  return (
    <>
      {" "}
      {suggestions.map((suggestion) => {
        return (
          <p key={suggestion.type}>
            Try {suggestion.suggestedValue} for {suggestion.type}
          </p>
        );
      })}
      {escapeSuggestions.length > 0 &&
        "Here are some suggestions to attain escape velocity"}
      {escapeSuggestions.map((suggestion) => {
        return (
          <p key={suggestion.type}>
            Try {suggestion.suggestedValue} for {suggestion.type}
          </p>
        );
      })}
    </>
  );
};
