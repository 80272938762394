// from https://www.irs.gov/pub/irs-tege/uniform_rmd_wksht.pdf
export const requiredMinDistributionData = {
  72: 27.4,
  73: 26.5,
  74: 25.5,
};

export const requiredDistribution = (savings: number, age: number): number => {
  if (age >= 70) return savings / 25;
  return 0;
};
