import { useState } from "react";
import * as t from "./Types";
import SimpleTable from "./SimpleTable";

export const round = (num: number): number => Number(num.toFixed(3));

export default function Table({ savings }: { savings: t.SavingsRow[] }) {
  const [open, setOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(-1);
  const formatter = Intl.NumberFormat("en-US");

  const _savings = open ? savings : savings.slice(0, 2);
  return (
    <div className="mt-8 pb-12">
      {open && (
        <a
          className="px-3 py-2 cursor-pointer rounded-md bg-light text-black all-small-caps"
          onClick={() => setOpen(false)}
        >
          Close
        </a>
      )}

      <div className="fixed top-2 left-4">
        {["withdrawal"].map((logKey, i) => {
          if (
            savings[selectedRow] &&
            savings[selectedRow].logs &&
            savings[selectedRow].logs![logKey as keyof t.Logs]
          ) {
            return (
              <SimpleTable
                key={logKey}
                className={"mb-0 border-0 border-b-0  invisible md:visible "}
                headers={["Amount", "Transform"]}
                data={savings[selectedRow].logs![logKey as keyof t.Logs]!.map(
                  (transform, i) => {
                    return {
                      key: i,
                      data: [
                        "$" + formatter.format(transform.amount),
                        transform.transform,
                      ],
                    };
                  }
                )}
              />
            );
          }
          return <div></div>;
        })}
      </div>

      <div className="fixed top-14 right-4">
        {["savings", "savings401k"].map((logKey, i) => {
          if (
            savings[selectedRow] &&
            savings[selectedRow].logs &&
            savings[selectedRow].logs![logKey as keyof t.Logs]
          ) {
            return (
              <SimpleTable
                key={logKey}
                className={"mb-0 border-0 border-b-0  invisible md:visible "}
                headers={["Amount", "Transform"]}
                data={savings[selectedRow].logs![logKey as keyof t.Logs]!.map(
                  (transform, i) => {
                    return {
                      key: i,
                      data: [
                        "$" + formatter.format(transform.amount),
                        transform.transform,
                      ],
                    };
                  }
                )}
              />
            );
          }
          return <div></div>;
        })}
      </div>

      <div className="mt-5 overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Year
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Age
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Savings
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                401k Savings
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Withdrawal
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Est. Tax Paid
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Rate of Return
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {_savings.map((row, i) => (
              <tr
                key={row.year}
                onMouseEnter={() => setSelectedRow(i)}
                onMouseLeave={() => setSelectedRow(-1)}
                className={i % 2 === 0 ? "bg-white" : "bg-ultralight"}
              >
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                  {row.year}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                  {row.age}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ${formatter.format(row.savings)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ${formatter.format(row.savings401k)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ${formatter.format(row.withdrawal)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ${formatter.format(row.estimatedTaxPaid)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {round(row.rateOfReturn * 100)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!open && (
          <div
            className="text-center cursor-pointer w-full py-4 pl-4 pr-3 text-base font-medium text-darkest bg-lighter all-small-caps"
            onClick={() => {
              setOpen(true);
            }}
          >
            Show more...
          </div>
        )}
      </div>
    </div>
  );
}
