import SimpleTable from "./SimpleTable";
import { useState } from "react";
import * as t from "./Types";

export const round = (num: number): number => Number(num.toFixed(3));

// source https://www.investopedia.com/ask/answers/042415/what-average-annual-return-sp-500.asp
export const historicalData = {
  1960: 0.0047,
  1961: 0.2689,
  1962: -0.0873,
  1963: 0.228,
  1964: 0.1648,
  1965: 0.1245,
  1966: -0.1006,
  1967: 0.2398,
  1968: 0.1106,
  1969: -0.085,
  1970: 0.0401,
  1971: 0.1431,
  1972: 0.1898,
  1973: -0.1466,
  1974: -0.2647,
  1975: 0.372,
  1976: 0.2384,
  1977: -0.0718,
  1978: 0.0656,
  1979: 0.1844,
  1980: 0.3242,
  1981: -0.0491,
  1982: 0.2155,
  1983: 0.2256,
  1984: 0.0627,
  1985: 0.3173,
  1986: 0.1867,
  1987: 0.0525,
  1988: 0.1661,
  1989: 0.3169,
  1990: -0.031,
  1991: 0.3047,
  1992: 0.0762,
  1993: 0.1008,
  1994: 0.0132,
  1995: 0.372,
  1996: 0.2268,
  1997: 0.331,
  1998: 0.2834,
  1999: 0.2089,
  2000: -0.0903,
  2001: -0.1185,
  2002: -0.2197,
  2003: 0.2836,
  2004: 0.1074,
  2005: 0.0483,
  2006: 0.1561,
  2007: 0.0548,
  2008: -0.3655,
  2009: 0.2594,
  2010: 0.1482,
  2011: 0.021,
  2012: 0.1589,
  2013: 0.3215,
  2014: 0.1352,
  2015: 0.0138,
  2016: 0.1177,
  2017: 0.2161,
  2018: -0.0423,
  2019: 0.3121,
  2020: 0.1802,
  2021: 0.2847,
  2022: -0.2055,
};

const tableize = (data: typeof historicalData) => {
  return Object.keys(data).map((key) => {
    return {
      key,
      // @ts-ignore
      data: [key, `${round(data[key] * 100)}%`],
    };
  });
};

export function TaxDataUsed() {
  const [open, setOpen] = useState(false);
  return (
    <div className="mt-4">
      <p
        className="px-3 py-2 inline cursor-pointer rounded-md bg-light text-black all-small-caps"
        onClick={() => setOpen(!open)}
      >
        What tax data is being used?
      </p>
      {open && (
        <div>
          <p
            className={
              "font-georgia text-lg text-darkest pt-4 my-2 transition ease-in bg-lighter p-4 rounded-xl mt-4 "
            }
          >
            If you chose to run the calculations using real historic market
            data, we're using the S&P 500 result.withdrawal401k of returns (with
            dividends) from{" "}
            <a href="https://www.investopedia.com/ask/answers/042415/what-average-annual-return-sp-500.asp">
              Investopedia
            </a>
            . Here are the returns by year:
          </p>

          <label className="inline-block lg:text-lg text-sm md:text-lg font-medium text-darkest all-small-caps">
            S&P 500 historical returns, with dividends
          </label>
          <SimpleTable
            className="pb-10 mb-8 mt-0"
            headers={["Year", "result.withdrawal401k of return"]}
            data={tableize(historicalData)}
          />
        </div>
      )}
    </div>
  );
}
