import { useState } from "react";
import SimpleTable from "./SimpleTable";
import * as t from "./Types";
import { jointBrackets, jointBracketsMN, dataYear } from "./tax";

const formatter = Intl.NumberFormat("en-US");

const tableize = (brackets: t.TaxBracket[]) => {
  return brackets.map((b) => {
    return {
      key: b.taxRate,
      data: [
        "$" + formatter.format(b.bottom),
        "$" + formatter.format(b.top),
        b.taxRate,
      ],
    };
  });
};

export function HowWasThisCalculated() {
  const [open, setOpen] = useState(false);
  return (
    <div className="mt-4">
      <p
        className="px-3 py-2 inline cursor-pointer rounded-md bg-light text-black all-small-caps"
        onClick={() => setOpen(!open)}
      >
        How was this calculated?
      </p>
      {open && (
        <div>
          <p
            className={
              "font-georgia text-lg text-darkest pt-4 my-2 transition ease-in bg-lighter p-4 rounded-xl mt-4 "
            }
          >
            Your effective tax rate is the tax you would pay on your income
            during retirement, such as from your 401(k), your pension, or Social
            Security. It is an estimate, calculated using today's federal tax
            brackets, state tax brackets, and FICA tax. We assume Minnesota for
            state taxes, and filing jointly. Compare this value to calculator
            like{" "}
            <a href="https://smartasset.com/taxes/income-taxes">this one</a>.
          </p>
          <p
            className={
              "font-georgia text-lg text-darkest pt-4 my-2 transition ease-in  mt-4 "
            }
          >
            Here are the tax brackets. This data is from {dataYear}.
          </p>
          <label className=" inline-block lg:text-sm text-sm md:text-lg font-medium text-darkest all-small-caps">
            Joint federal tax brackets
          </label>
          <SimpleTable
            className="pb-10 mb-8 mt-0"
            headers={["Min", "Max", "Tax Rate"]}
            data={tableize(jointBrackets)}
          />
          <label className=" inline-block lg:text-sm text-sm md:text-lg font-medium text-darkest all-small-caps">
            Joint Minnesota state tax brackets
          </label>
          <SimpleTable
            className="pb-10 mb-8 mt-0"
            headers={["Min", "Max", "Tax Rate"]}
            data={tableize(jointBracketsMN)}
          />
        </div>
      )}
    </div>
  );
}
