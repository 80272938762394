import { useState, useReducer, useEffect } from "react";

import UserInput from "./UserInput";

import Table from "./Table";
import Saved from "./Saved";

import SimpleTable from "./SimpleTable";

import { calculateFlexTable, flexOptions, Flex } from "./Flex";

import * as t from "./Types";
import { HowWasThisCalculated } from "./EffectiveTax";
import { round, projectedData } from "./projections";
import { search } from "./Suggestions";
import { TaxDataUsed } from "./SP500";

const initialState: t.State = {
  interestRate: 0.1,
  currentSavings: 100000,
  currentSavings401k: 0,
  yearlyAddition: 0,
  yearlyAddition401k: 0,
  currentAge: 36,
  retirementAge: 65,
  liveTillAge: 90,
  monthlyWithdrawal: 6000 * 0.6,
  inflationRate: 0.03,
  interestDuringRetirement: 0.04,
  pension: 0,
  pensionStartAge: 0,
  socialSecurity: 0,
  incomeDuringRetirement: 0,
  incomeDuringRetirementLastsTillAge: 0,
  interestDuringIncomeDuringRetirement: 0.1,
  flexAttr: "inflationRate",
  flexMin: 0.02,
  flexMax: 0.04,
  ratio401k: 0.1,
  realReturnsStartingFromYear: 0,
};

function setLimits(state: t.State) {
  const newState = { ...state };
  if (newState.currentSavings < 0) newState.currentSavings = 0;
  if (newState.currentSavings401k < 0) newState.currentSavings401k = 0;
  if (newState.yearlyAddition < 0) newState.yearlyAddition = 0;
  if (newState.yearlyAddition401k < 0) newState.yearlyAddition401k = 0;
  if (newState.currentAge < 0) newState.currentAge = 0;
  if (newState.retirementAge < 0) newState.retirementAge = 0;
  if (newState.liveTillAge < 0) newState.liveTillAge = 0;
  if (newState.monthlyWithdrawal < 0) newState.monthlyWithdrawal = 0;
  if (newState.inflationRate < 0) newState.inflationRate = 0;
  if (newState.interestRate < 0) newState.interestRate = 0;
  if (newState.interestDuringRetirement < 0)
    newState.interestDuringRetirement = 0;
  if (newState.pension < 0) newState.pension = 0;
  if (newState.pensionStartAge < 0) newState.pensionStartAge = 0;
  if (newState.socialSecurity < 0) newState.socialSecurity = 0;
  if (newState.incomeDuringRetirement < 0) newState.incomeDuringRetirement = 0;
  if (newState.incomeDuringRetirementLastsTillAge < 0)
    newState.incomeDuringRetirementLastsTillAge = 0;
  if (newState.interestDuringIncomeDuringRetirement < 0)
    newState.interestDuringIncomeDuringRetirement = 0;
  if (newState.ratio401k < 0) newState.ratio401k = 0;
  if (newState.realReturnsStartingFromYear < 0)
    newState.realReturnsStartingFromYear = 0;
  if (newState.flexMin < 0) newState.flexMin = 0;
  if (newState.flexMax < 0) newState.flexMax = 0;
  if (newState.flexMin > newState.flexMax) newState.flexMin = newState.flexMax;
  if (newState.flexMax < newState.flexMin) newState.flexMax = newState.flexMin;

  if (newState.retirementAge < newState.currentAge)
    newState.retirementAge = newState.currentAge;
  if (newState.currentAge > 120) newState.currentAge = 120;
  if (newState.retirementAge > 120) newState.retirementAge = 120;
  if (newState.liveTillAge > 120) newState.liveTillAge = 120;
  if (newState.pensionStartAge > 120) newState.pensionStartAge = 120;
  if (newState.incomeDuringRetirementLastsTillAge > 120)
    newState.incomeDuringRetirementLastsTillAge = 120;

  return newState;
}

const reducer = (s: t.State, action: t.Action) => {
  switch (action.type) {
    case "SET":
      return { ...s, [action.key!]: action.val! };
    case "SETALL":
      // NOT using setURLAndStorage, so I can call 'replaceState' just once,
      // so I don't run into the 'history.replaceState called too many times' error
      const params = new URLSearchParams(document.location.search);
      for (let key of Object.keys(action.state!)) {
        let val = action.state![key as keyof t.State].toString();
        params.set(key, val);
        localStorage.setItem(key, val);
      }
      /* eslint-disable */
      history.pushState(null, "", "/?" + params.toString());
      return action.state!;
    default:
      return s;
  }
};

const getStateFromCacheOrURL = (
  state: t.State,
  set: (key: keyof t.State, val: any) => void
) => {
  let newState: any = { ...state };

  const params = new URLSearchParams(document.location.search);

  for (let key of Object.keys(newState)) {
    let fromURL = params.get(key);
    let item = localStorage.getItem(key);
    if (fromURL) {
      newState[key as keyof t.State] = fromURL;
    } else if (item) {
      newState[key as keyof t.State] = item;
    }
  }

  for (let key of Object.keys(newState)) {
    set(key as keyof t.State, newState[key]);
  }
};

const setURLAndStorage = (
  key: keyof t.State,
  val: string,
  pushState: boolean = false
) => {
  const params = new URLSearchParams(document.location.search);
  params.set(key, val);
  /* eslint-disable */

  history.replaceState(null, "", "/?" + params.toString());
  localStorage.setItem(key, val);
};
const formatter = Intl.NumberFormat("en-US");

const mergeStates = (state1: t.State, state2: t.State) => {
  let merged: t.State = { ...state1 };
  for (let _key of Object.keys(merged)) {
    const key: keyof t.State = _key as keyof t.State;
    const val: any = state2[key];
    if (val !== "" && val !== null) {
      // @ts-ignore
      merged[key] = val;
    }
  }

  return merged;
};

export default function Container() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState("");
  const params = new URLSearchParams(document.location.search);

  const debug = params.get("debug");
  const set = (key: keyof t.State, val: string): void => {
    let _val: number | string = "";
    if (key === "flexAttr") {
      _val = val;
    } else {
      if (val === "") {
        console.log("empty val");
        _val = val;
      } else if (val && val.slice) {
        let last = val.slice(-1);
        let lastNum = Number.parseInt(last);
        if (Number.isNaN(lastNum) || val === "0.0" || val === "0.00") {
          console.log("val is NaN or 0.0 or 0.00");
          _val = val;
        } else {
          _val = Number.parseFloat(val);
          console.log("val", val, "parsed as float tooo", _val);
        }
      } else {
        _val = Number.parseFloat(val);
        console.log("val", val, "parsed as float to", _val);
      }
    }
    console.log("set", key, _val, "val given was:", val);
    setURLAndStorage(key, _val.toString());

    dispatch({ type: "SET", key, val: _val });
  };

  useEffect(() => {
    getStateFromCacheOrURL(state, set);
  }, []);
  let data,
    suggestedWithdrawal,
    suggestedRetirementAge,
    avgRateOfReturn,
    lowestTaxRate,
    highestTaxRate,
    returns,
    flexTable;

  try {
    let stateWithDefaults = mergeStates(initialState, state);
    stateWithDefaults = setLimits(stateWithDefaults);
    data = projectedData(stateWithDefaults, true);
    const rates = data.savingsRows
      .filter((row) => row.effectiveTaxRate > 0)
      .map((row) => row.effectiveTaxRate)
      .sort();

    lowestTaxRate = rates.length === 0 ? 0 : round(rates[0] * 100);
    highestTaxRate =
      rates.length === 0 ? 0 : round(rates[rates.length - 1] * 100);

    const maxAge =
      stateWithDefaults.incomeDuringRetirementLastsTillAge ||
      stateWithDefaults.retirementAge;
    returns = data.savingsRows
      .filter((row) => row.age < maxAge)
      .map((row) => row.rateOfReturn);

    avgRateOfReturn =
      returns.length > 0 ? returns.reduce((a, b) => a + b) / returns.length : 0;

    flexTable = calculateFlexTable(stateWithDefaults);

    suggestedWithdrawal = 0;
    suggestedRetirementAge = 0;

    if (data.runOutYear) {
      suggestedWithdrawal = search(
        0,
        100000,
        stateWithDefaults,
        "monthlyWithdrawal",
        true
      );

      suggestedRetirementAge = search(
        0,
        100,
        stateWithDefaults,
        "retirementAge",
        false
      );
    }
  } catch (error) {
    setError(JSON.stringify(error));
  }

  if (error || data === undefined) return <p>{error}</p>;
  /*
  const suggestions: t.Suggestion[] = getSuggestions(data, { ...state });

  const escapeSuggestions: t.Suggestion[] = getSuggestionsEscape(data, {
    ...state,
  });*/

  return (
    <>
      <div>
        <div
          className={
            "visible md:invisible fixed bottom-0 left-0 right-0 py-1 block " +
            (data.runOutYear ? "bg-gloss" : "bg-glossalt")
          }
        ></div>
        <div
          className={
            "invisible md:visible lg:text-xl font-georgia fixed top-0 right-0 m-4 px-3 py-2 text-darkest rounded-md block " +
            (data.runOutYear ? "bg-gloss" : "bg-glossalt")
          }
        >
          ${formatter.format(data.savingsStartsAt)}
        </div>

        {debug && (
          <div className="fixed bottom-0 left-0 py-4 px-4 m-4 rounded-md bg-light w-72 h-72">
            <textarea
              className="w-full h-full"
              readOnly
              value={JSON.stringify(state)}
            />
          </div>
        )}

        <div className="container mx-auto mt-16 md:max-w-xl lg:max-w-2xl sm:max-w-sm max-w-xs">
          <h1 className="font-display text-3xl md:text-6xl text-darkest pb-4 border-b md:border-b-2 border-gloss mb-0 text-center">
            Retirement calculator
          </h1>
        </div>
        <div className="container mx-auto md:max-w-2xl max-w-xs">
          <UserInput
            state={state}
            onChange={(key: keyof t.State, val: any) => set(key, val)}
          />
        </div>
        <div className="container mx-auto mt-10 md:max-w-2xl max-w-xs">
          <h2 className="text-3xl font-georgia mb-2 text-darkest">Results</h2>
          <p
            className={
              "font-georgia text-xl text-darkest py-4 px-6 rounded-xl mb-2 " +
              (data.runOutYear ? "bg-gloss" : "bg-glossalt")
            }
          >
            {data.runOutYear &&
              `You will run out of money by age ${data.runOutYear}.`}
            {!data.runOutYear && `Your savings will last you.`}
            <br />
            You start retirement with ${formatter.format(
              data.savingsStartsAt
            )}{" "}
            and end with ${formatter.format(data.savingsEndsAt)}.
          </p>
          <p className={"font-georgia text-xl text-darkest pt-4 my-2 "}>
            {data.runOutYear &&
              `You can make your income last by reducing your withdrawal to $${formatter.format(
                suggestedWithdrawal!
              )}, or you could wait to retire at ${suggestedRetirementAge}. `}
            {lowestTaxRate !== highestTaxRate &&
              `Your effective income tax rate ranges from ${lowestTaxRate}% to 
            ${highestTaxRate}%.`}{" "}
            {lowestTaxRate === highestTaxRate &&
              `Your effective income tax rate is ${lowestTaxRate}%.`}{" "}
            {lowestTaxRate === 0 &&
              highestTaxRate === 0 &&
              "This is probably because you didn't provide a source of income that would be taxed as income tax, such as a 401k, or social security. "}
            <br />
            Income will be taxed at this rate, and investments at the 15% long
            term capital gains tax.
          </p>
          <p className={"font-georgia text-xl text-darkest pt-4 my-2 "}>
            Your average rate of return is {round(avgRateOfReturn! * 100)}% over{" "}
            {returns!.length} years.{" "}
            {debug &&
              "(" +
                returns!.map((ret) => `${round(ret * 100)}`).join(" + ") +
                ") / " +
                returns!.length}
          </p>
          <HowWasThisCalculated />
          <TaxDataUsed />
          {/*<div className="">
            <Graph data={data.graphData} />
            </div>*/}

          <Table savings={data.savingsRows} />

          <Flex
            flexAttr={state.flexAttr}
            flexMin={state.flexMin}
            flexMax={state.flexMax}
            onChange={(key: keyof t.State, val: any) => set(key, val)}
            className="border-t border-t-gloss mt-0 pt-10"
          />
          {flexTable! && (
            <SimpleTable
              className="pb-10 mb-8"
              headers={[
                flexOptions[state.flexAttr as keyof typeof flexOptions],
                "Monthly Withdrawal",
              ]}
              data={flexTable!}
            />
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 pb-10 gap-4 border-b border-b-gloss">
            <div>
              <label className=" inline-block font-medium lg:text-sm text-sm md:text-lg text-darkest all-small-caps">
                Share
              </label>
              <input
                type="text"
                className={
                  "w-full rounded-md outline-dark shadow-sm text-darkest border-none text-base mt-2"
                }
                value={window.location.href}
                readOnly
              />
            </div>
            <div>
              <label className="hidden md:inline-block font-medium lg:text-sm text-sm md:text-lg text-darkest all-small-caps">
                Clear
              </label>
              <p
                className="px-3 py-2 mt-2 inline-block w-full h-min cursor-pointer rounded-md bg-light text-black all-small-caps text-center"
                onClick={() => {
                  localStorage.clear();
                  dispatch({ type: "SETALL", state: initialState });
                }}
              >
                Clear local storage
              </p>
            </div>
          </div>
          <Saved
            state={state}
            savingsStartsAt={data.savingsStartsAt}
            savingsEndsAt={data.savingsEndsAt}
            onClick={(state) => {
              dispatch({ type: "SETALL", state });
            }}
          />
          <div className="mt-0 pb-10">
            <p className="text-sm my-0 text-dark">
              This is not legal advice. Use at your own risk. We collect no
              data, all calculations are happening inside your browser. We set
              no cookies, but do set values on local storage so your inputs are
              saved for next time. You can clear this using the clear local
              storage button. Made with ❤️ by{" "}
              <a href="https://adit.io">adit.io</a>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
