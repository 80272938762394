import * as t from "./Types";
import { estimatedTax } from "./tax";
import { requiredDistribution } from "./IRA";
import { historicalData } from "./SP500";
const times = (startVal: number, val: number, func: (i: number) => any) => {
  let i = startVal;
  let endVal = startVal + val;
  while (i <= endVal) {
    func(i);
    i += 1;
  }
};

export const round = (num: number): number => Number(num.toFixed(1));

const calculateRunOut = (rows: t.SavingsRow[]): number | null => {
  const index = rows.findIndex((row) => row.savings + row.savings401k < 0);
  if (index < 0) return null;
  return rows[index].age;
};

type Withdrawal401k = {
  amount: number;
  usedMinDistribution: boolean;
};

const w4 = (amount: number, usedMinDistribution: boolean): Withdrawal401k => {
  return { amount, usedMinDistribution };
};

export const adjustWithdrawalForPensionEtc = (
  state: t.State,
  withdrawal: number,
  age: number,
  effectiveTaxRate: number
): { withdrawal: number; taxPaid: number; taxableIncome: number } => {
  let taxPaid = 0;
  let taxableIncome = 0;
  const adjustAmount = (money: number) => {
    let amount = money * 12 * (1 - effectiveTaxRate);
    if (amount > withdrawal) amount = withdrawal;
    withdrawal -= amount;

    taxPaid += amount / (1 - effectiveTaxRate) - amount;
    if (amount > withdrawal) {
      taxableIncome += amount;
    } else {
      taxableIncome += money * 12;
    }
  };
  if (age >= state.pensionStartAge) {
    adjustAmount(state.pension);
  }

  // Minimum age for Social Security withdrawal
  if (age >= 70) {
    adjustAmount(state.socialSecurity);
  }

  if (age <= state.incomeDuringRetirementLastsTillAge) {
    adjustAmount(state.incomeDuringRetirement);
  }
  return { withdrawal, taxPaid, taxableIncome };
};

export const calculateTaxes = (
  withdrawal: number,
  withdrawal401k: number,
  effectiveTaxRate: number,
  earlyWithdrawalPenaltyApplies: boolean = false
) => {
  let withdrawalWithTaxes = withdrawal / 0.85;
  let withdrawal401kWithTaxes = withdrawal401k / (1 - effectiveTaxRate);
  if (earlyWithdrawalPenaltyApplies) {
    withdrawal401kWithTaxes += withdrawal401k * 0.1;
  }
  const taxPaid =
    withdrawalWithTaxes -
    withdrawal +
    (withdrawal401kWithTaxes - withdrawal401k);
  return {
    withdrawalWithTaxes,
    withdrawal401kWithTaxes,
    taxPaid,
    earlyWithdrawalPenaltyApplies,
  };
};

export const findWithdrawals = (
  state: t.State,
  savings: number,
  savings401k: number,
  withdrawal: number,
  age: number
) => {
  const make = (
    withdrawal: number,
    withdrawal401k: Withdrawal401k,
    earlyWithdrawalPenaltyApplies: boolean
  ) => {
    return { withdrawal, withdrawal401k, earlyWithdrawalPenaltyApplies };
  };
  if (savings <= 0 && savings401k > 0) {
    let earlyWithdrawalPenaltyApplies = age < 60;
    return make(0, w4(withdrawal, false), earlyWithdrawalPenaltyApplies);
  } else if (savings > 0 && savings401k <= 0) {
    return make(withdrawal, w4(0, false), false);
  } else if (savings <= 0 && savings401k <= 0) {
    // There's no legal operation here, so we're just going to
    // consistently send only the savings into negative
    return make(withdrawal, w4(0, false), false);
  }
  const withdrawal401k = find401kWithdrawal(
    state,
    savings401k,
    withdrawal,
    age
  );
  return make(withdrawal - withdrawal401k.amount, withdrawal401k, false);
};

export const find401kWithdrawal = (
  state: t.State,
  savings401k: number,
  withdrawal: number,
  age: number
): Withdrawal401k => {
  if (age < 60) return w4(0, false);
  if (savings401k <= 0) return w4(0, false);
  let minDistribution = requiredDistribution(savings401k, age);
  if (minDistribution > withdrawal) {
    minDistribution = withdrawal;
  }
  if (age >= 70) {
    return w4(minDistribution, true);
  }
  let toWithdrawFrom401k = withdrawal * state.ratio401k;
  if (toWithdrawFrom401k > savings401k) {
    return w4(minDistribution, true);
  }
  return w4(toWithdrawFrom401k, false);
};

export const calculateEffectiveTaxRate = (
  state: t.State,
  age: number,
  taxableIncome: number
): number => {
  const taxData = estimatedTax(taxableIncome, true, false);

  const effectiveTaxRate =
    taxData.fed.effectiveRate +
    taxData.state.effectiveRate +
    taxData.fica.effectiveRate;

  return effectiveTaxRate;
};

export const getRateOfReturn = (age: number, state: t.State, i: number) => {
  let returnYear = state.realReturnsStartingFromYear + i;
  // @ts-ignore
  let realReturn = historicalData[returnYear];

  if (age < state.retirementAge) {
    if (realReturn) return realReturn;
    return state.interestRate;
  } else if (
    age >= state.retirementAge &&
    state.incomeDuringRetirementLastsTillAge &&
    age <= state.incomeDuringRetirementLastsTillAge
  ) {
    if (realReturn) return realReturn;
    return state.interestDuringIncomeDuringRetirement;
  } else {
    // you're retired, no additional income, so play it safe
    // never use market returns here
    return state.interestDuringRetirement;
  }
};

export const projectedData = (
  state: t.State,
  calculateEffectiveTax: boolean = false
): t.ProjectedData => {
  let savings = state.currentSavings || 0;
  let savings401k = state.currentSavings401k || 0;
  let ratio401k = state.ratio401k || 0;
  let graphData: t.Coord[] = [];
  let savingsRows: t.SavingsRow[] = [];

  let yearsToRetirement = 1;
  if (state.retirementAge > state.currentAge) {
    yearsToRetirement = state.retirementAge - state.currentAge;
  }
  times(0, yearsToRetirement - 1, (i) => {
    let logs: t.Logs = { savings: [], savings401k: [] };
    let age = state.currentAge + i;
    let rateOfReturn = getRateOfReturn(age, state, i);

    const logSavings = (amount: number, transform: string) =>
      logs.savings!.push({ amount: round(amount), transform });

    const log401k = (amount: number, transform: string) =>
      logs.savings401k!.push({ amount: round(amount), transform });

    logSavings(savings, "savings at start");
    log401k(savings401k, "401k savings at start");

    const prevSavings = savings;
    const prevSavings401k = savings401k;
    savings = savings * (1 + rateOfReturn) + state.yearlyAddition;
    savings = round(savings);
    savings401k = savings401k * (1 + rateOfReturn) + state.yearlyAddition401k;
    savings401k = round(savings401k);

    logSavings(savings - prevSavings, "+ interest");
    log401k(savings401k - prevSavings401k, "+ interest");

    logSavings(savings, "value at end of year");
    log401k(savings401k, "value at end of year");

    graphData.push({
      x: i + state.currentAge,
      y: (savings + savings401k) / 1000,
    });

    savingsRows.push({
      year: 2022 + i,
      age,
      savings,
      savings401k,
      withdrawal: 0,
      effectiveTaxRate: 0,
      estimatedTaxPaid: 0,
      logs,
      rateOfReturn,
    });
  });
  let last = savingsRows[savingsRows.length - 1];

  const savingsStartsAt = last ? last.savings + last.savings401k : 0;

  let yearsInRetirement = state.liveTillAge - state.retirementAge;
  let effectiveTaxRate = 0.4;

  times(yearsToRetirement, yearsInRetirement, (year) => {
    let logs: t.Logs = { withdrawal: [], savings: [], savings401k: [] };

    const log = (amount: number, transform: string) =>
      logs.withdrawal!.push({ amount: round(amount), transform });

    const logSavings = (amount: number, transform: string) =>
      logs.savings!.push({ amount: round(amount), transform });

    const log401k = (amount: number, transform: string) =>
      logs.savings401k!.push({ amount: round(amount), transform });

    logSavings(savings, "savings at start");
    log401k(savings401k, "401k savings at start");
    const prevSavings = savings;
    const prevSavings401k = savings401k;

    let withdrawal = state.monthlyWithdrawal;

    log(withdrawal, "monthly withdrawal");

    withdrawal = withdrawal * 12;
    log(withdrawal, "yearly withdrawal");

    /*

    withdrawal = withdrawal * (1 + state.inflationRate) ** (year - 1);
    log(withdrawal, "adjusted for inflation");*/

    let age = state.currentAge + year;

    let adjustForInflation = (1 + state.inflationRate) ** year;

    if (calculateEffectiveTax) {
      let taxableIncome = findWithdrawals(
        state,
        savings,
        savings401k,
        withdrawal,
        age
      ).withdrawal401k.amount;

      taxableIncome += adjustWithdrawalForPensionEtc(
        state,
        withdrawal,
        age,
        effectiveTaxRate
      ).taxableIncome;

      log(taxableIncome, "taxableIncome");

      effectiveTaxRate = calculateEffectiveTaxRate(state, age, taxableIncome);
    }

    let pensionEtcResult = adjustWithdrawalForPensionEtc(
      state,
      withdrawal,
      age,
      effectiveTaxRate
    );

    withdrawal = pensionEtcResult.withdrawal;
    log(
      withdrawal,
      "withdrawal after pension, social security, income after retirement"
    );

    let withdrawals = findWithdrawals(
      state,
      savings,
      savings401k,
      withdrawal,
      age
    );
    let append = withdrawals.withdrawal401k.usedMinDistribution
      ? " (min distribution used)"
      : "";

    log(withdrawals.withdrawal401k.amount, "401k withdrawal");
    log(withdrawals.withdrawal, "non-401k withdrawal");

    log(
      withdrawals.withdrawal401k.amount * adjustForInflation,
      "401k withdrawal, adjusted for inflation" + append
    );
    log(
      withdrawals.withdrawal * adjustForInflation,
      "non-401k withdrawal, adjusted for inflation"
    );

    let withdrawalsWithTaxes = calculateTaxes(
      withdrawals.withdrawal * adjustForInflation,
      withdrawals.withdrawal401k.amount * adjustForInflation,
      effectiveTaxRate,
      withdrawals.earlyWithdrawalPenaltyApplies
    );

    savings -= withdrawalsWithTaxes.withdrawalWithTaxes;
    savings401k -= withdrawalsWithTaxes.withdrawal401kWithTaxes;

    log(
      withdrawalsWithTaxes.withdrawal401kWithTaxes,
      "401k withdrawal with taxes"
    );
    log(
      withdrawalsWithTaxes.withdrawalWithTaxes,
      "non-401k withdrawal with taxes"
    );

    log(effectiveTaxRate, "effective tax rate");

    let actualWithdrawal =
      withdrawalsWithTaxes.withdrawalWithTaxes +
      withdrawalsWithTaxes.withdrawal401kWithTaxes;
    logSavings(prevSavings - savings, "- withdrawals");

    if (withdrawalsWithTaxes.earlyWithdrawalPenaltyApplies) {
      log401k(
        savings401k,
        "after withdrawals, early withdrawal penalty applies" + append
      );
    } else {
      log401k(prevSavings401k - savings401k, " - withdrawals" + append);
    }

    let rateOfReturn = getRateOfReturn(age, state, year);

    if (savings > 0) {
      const savingsBeforeInterest = savings;

      savings = savings * (1 + rateOfReturn);
      savings = round(savings);
      logSavings(savings - savingsBeforeInterest, "+ interest");
    }

    if (savings401k > 0) {
      const savingsBeforeInterest401k = savings401k;
      savings401k = savings401k * (1 + rateOfReturn);

      savings401k = round(savings401k);
      log401k(savings401k - savingsBeforeInterest401k, "+ interest");
    }
    let estimatedTaxPaid =
      withdrawalsWithTaxes.taxPaid + pensionEtcResult.taxPaid;

    log(estimatedTaxPaid, "estimated tax paid");
    logSavings(savings, "savings at end");
    log401k(savings401k, "401k savings at end");

    graphData.push({
      x: year + state.currentAge,
      y: (savings + savings401k) / 1000,
    });
    savingsRows.push({
      year: 2022 + year,
      age,
      savings,
      savings401k,
      withdrawal: round(actualWithdrawal),
      effectiveTaxRate,
      estimatedTaxPaid,
      logs,
      rateOfReturn,
    });
  });

  last = savingsRows[savingsRows.length - 1];
  const savingsEndsAt = last.savings + last.savings401k;
  const runOutYear = calculateRunOut(savingsRows);
  let res = {
    graphData,
    savingsRows,
    yearsToRetirement,
    yearsInRetirement,
    savingsStartsAt,
    savingsEndsAt,
    runOutYear,
  };

  return res;
};
